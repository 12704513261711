<script lang="ts" setup></script>

<template>
  <div class="dropdown-content">
    <slot>
      <!-- If there isn't any content, render an empty space so the component can act as a spacer-->
      &nbsp;
    </slot>
  </div>
</template>

<style lang="scss" scoped>
// These classnames match Bootstrap's syntax
/* stylelint-disable selector-class-pattern */
.dropdown-content {
  display: inline-block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
}
/* stylelint-enable selector-class-pattern */
</style>
