import type MessageBoxModal from "./MessageBoxModal.vue";
import type { ConfirmOptions, OkOptions } from "./MessageBoxModal.vue";
import type { ComputedRef} from "vue";

import { inject, onBeforeUnmount } from "vue";

export const MESSAGE_BOX_MODAL_INJECTION_KEY = Symbol('MessageBoxModal component');

export default function useModals() {
  // MessageBoxModal is provided by App.vue so that it can be used globally
  const modal = inject<ComputedRef<InstanceType<typeof MessageBoxModal>>>(MESSAGE_BOX_MODAL_INJECTION_KEY);

  // If the component is somehow unmounted without closing the modal, ensure it's closed
  onBeforeUnmount(() => modal?.value.close(false));

  return {
    showOk: (options: OkOptions) => modal!.value.showOk(options),
    showConfirm: (options: ConfirmOptions) => modal!.value.showConfirm(options),
  }
}
