<script lang="ts" setup>
import type { Customer } from '@console/services/customer.models';

import { computed, ref } from 'vue';

import { useVuexStore } from '@console/state/vuex/store';
import { useAuthStore } from '@shared/state/auth.store';

import ProfileDropdownItem from '@console/components/navigation/ProfileDropdownItem.vue';
import ProfileImage from '@console/components/navigation/ProfileImage.vue';
import Dropdown from '@shared/design/dropdowns/Dropdown.vue';
import BillingIcon from '@shared/design/icons/BillingIcon.vue';
import UserIcon from '@shared/design/icons/UserIcon.vue';

defineProps<{
  isMobile?: boolean;
}>();

const authStore = useAuthStore();

const vuexStore = useVuexStore();
const customers = computed<Customer[]>(() => Object.values(vuexStore.state.customer.customers));
const selectedCompanyId = computed(() => vuexStore.state.customer.selectedCompanyId);
const companyName = computed(() => vuexStore.getters['customer/companyName']);
const isSubscribed = computed(() => vuexStore.getters['customer/isSubscribed']);
const isDemo = computed(() => vuexStore.getters['customer/isDemo']);

const otherCompaniesExpanded = ref(false);

const canSwitchCustomers = computed(() => customers.value.length > 1);
const hasCustomer = computed(() => customers.value.length > 0);
const otherCompanies = computed(() => customers.value.filter(c => c.id !== selectedCompanyId.value));
const userFullName = computed(() => authStore.profile?.name);
const showCustomerDropdownItems = computed(() => hasCustomer.value && !isDemo.value);
const isOwner = computed(() => authStore.role(selectedCompanyId.value) === 'Owner');

const onSwitchCompanyClicked = () => (otherCompaniesExpanded.value = !otherCompaniesExpanded.value);
</script>

<template>
  <div v-if="authStore.profile">
    <Dropdown
      variant="transparent"
      toggle-class="profileToggle"
      dropdown-placement="bottom-end"
      :dropdown-class="['py-0 rounded-0', { 'mt-2': !!isMobile }]"
    >
      <template v-slot:toggleContent>
        <div class="profile">
          <div class="pl-4 pr-3">
            <div v-if="companyName" class="text-truncate text-nowrap text-right">
              {{ companyName }}
            </div>
            <div class="small font-weight-light text-truncate text-nowrap text-right">
              {{ userFullName }}
            </div>
          </div>
        </div>
      </template>
      <div id="profile-button-menu" class="bg-white">
        <ProfileDropdownItem
          v-if="hasCustomer && !isDemo && !authStore.isFederated && !authStore.isEmployee"
          :to="{ name: 'user_profile' }"
        >
          <template v-slot:left>
            <ProfileImage :profile="authStore.profile" small />
          </template>
          <template v-slot:right>
            <span>Profile</span>
          </template>
        </ProfileDropdownItem>
        <ProfileDropdownItem v-if="showCustomerDropdownItems && isOwner && isSubscribed" :to="{ name: 'billing' }">
          <template v-slot:left>
            <BillingIcon />
          </template>
          <template v-slot:right>
            <span>Billing</span>
          </template>
        </ProfileDropdownItem>
        <ProfileDropdownItem v-if="showCustomerDropdownItems && isOwner" :to="{ name: 'user_management' }">
          <template v-slot:left>
            <UserIcon />
          </template>
          <template v-slot:right>
            <span>User Management</span>
          </template>
        </ProfileDropdownItem>
        <ProfileDropdownItem
          v-if="showCustomerDropdownItems && canSwitchCustomers && !authStore.isFederated"
          leave-open
          @click="onSwitchCompanyClicked"
        >
          <template v-slot:left>
            <BaseIcon name="building" variant="far" />
          </template>
          <template v-slot:right>
            <span class="mr-2">Switch Company</span>
            <BaseIcon v-if="!otherCompaniesExpanded" name="angle-down" />
            <BaseIcon v-if="otherCompaniesExpanded" name="angle-up" />
          </template>
        </ProfileDropdownItem>
        <div v-show="otherCompaniesExpanded">
          <ProfileDropdownItem
            v-for="company in otherCompanies"
            :key="company.id"
            class="withBorderTop"
            :to="{ name: 'root', query: { switchToCompanyId: company.id } }"
          >
            <template v-slot:right> {{ company.company_name }} </template>
          </ProfileDropdownItem>
        </div>
        <ProfileDropdownItem class="withBorderTop" @click="authStore.logOut">
          <template v-slot:left>
            <BaseIcon name="sign-out-alt" />
          </template>
          <template v-slot:right>
            <span>Log Out</span>
          </template>
        </ProfileDropdownItem>
      </div>
    </Dropdown>
  </div>
</template>

<style lang="scss" scoped>
@import '@shared/scss/colors.scss';
@import 'bootstrap/scss/_functions.scss';
@import 'bootstrap/scss/_variables.scss';
@import 'bootstrap/scss/mixins/_breakpoints.scss';

:deep(.profileToggle) {
  padding-right: 1rem;
  padding-left: 0;

  // Use a negative margin and then an equal padding so that the dropdown menu is aligned with the edge of the screen
  margin-right: -1rem;
}

.profile {
  display: flex;
  align-items: center;
  justify-content: center;
  color: $gray-700;
  border-left: none;

  > div:first-child {
    font-weight: 500;
  }

  @include media-breakpoint-up(md) {
    border-left: 1px solid $gray-400;
  }
}

.withBorderTop {
  border-top: 1px solid $gray-200;
}

.navigationPopover {
  font-size: 0.95rem;
}
</style>
