<script>
import { mapGetters } from 'vuex';

import { dateFormat, dateUtc } from '@shared/utilities/filters';
import NumberHelpers from '@shared/utilities/number_helpers';

import TextTooltip from '@shared/design/TextTooltip.vue';

export default {
  components: {
    TextTooltip,
  },
  computed: {
    ...mapGetters('customer', ['incrementalLifetimeSavings', 'lifetimeSavingsDate']),
    hasIncrementalLifetimeSavings() {
      return this.incrementalLifetimeSavings && this.incrementalLifetimeSavings > 0;
    },
    showTooltip() {
      return this.lifetimeSavingsDate;
    },
    incrementalLifetimeSavingsDisplay() {
      return NumberHelpers.formatDollars(this.incrementalLifetimeSavings);
    },
  },
  methods: {
    formatDate(date, format = 'MMMM D, YYYY') {
      return dateFormat(dateUtc(date), format);
    },
  },
};
</script>

<template>
  <div v-if="hasIncrementalLifetimeSavings">
    <TextTooltip placement="bottom">
      Incremental Lifetime Savings:
      <template v-if="showTooltip" #tooltip>
        <strong>Incremental Lifetime Savings</strong> includes all savings generated from commitments managed by
        ProsperOps, above and beyond what you would have otherwise saved (assuming you continued achieving your baseline
        Effective Savings Rate), less ProsperOps charges, as of
        {{ formatDate(lifetimeSavingsDate) }}
      </template>
    </TextTooltip>
    <span class="savings">{{ incrementalLifetimeSavingsDisplay }}</span>
  </div>
</template>

<style lang="scss" scoped>
@import '@shared/scss/colors.scss';

.savings {
  margin-left: 0.8rem;
  font-weight: bold;
  color: map-get($theme-colors, 'success');
}
</style>
