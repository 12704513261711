<script>
import AwsEc2Icon from '@shared/design/icons/AwsEc2Icon.vue';
import AwsElasticacheIcon from '@shared/design/icons/AwsElasticacheIcon.vue';
import AwsMemorydbIcon from '@shared/design/icons/AwsMemorydbIcon.vue';
import AwsOpensearchIcon from '@shared/design/icons/AwsOpensearchIcon.vue';
import AwsRdsIcon from '@shared/design/icons/AwsRdsIcon.vue';
import AwsRedshiftIcon from '@shared/design/icons/AwsRedshiftIcon.vue';
import AzureVirtualMachinesIcon from '@shared/design/icons/AzureVirtualMachinesIcon.vue';
import GcpComputeEngineIcon from '@shared/design/icons/GcpComputeEngineIcon.vue';
import GoogleCloudSqlIcon from '@shared/design/icons/GoogleCloudSQLIcon.vue';

export default {
  components: {
    AwsEc2Icon,
    AwsElasticacheIcon,
    AwsMemorydbIcon,
    AwsOpensearchIcon,
    AwsRdsIcon,
    AwsRedshiftIcon,
    AzureVirtualMachinesIcon,
    GcpComputeEngineIcon,
    GoogleCloudSqlIcon,
  },
  props: {
    icon: {
      type: String,
      required: true,
    },
  },
  computed: {},
};
</script>

<template>
  <div class="navSubSection">
    <div class="navSubSectionHeader">
      <component :is="icon" class="navSubSectionIcon mr-3" />
      <slot name="header"></slot>
    </div>
    <div class="navSubSectionBody">
      <ul class="navSubSectionList">
        <slot name="items"></slot>
      </ul>
    </div>
  </div>
</template>

<style scoped>
.navSubSectionHeader {
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem;
  color: rgba(255, 255, 255, 0.85);
  opacity: 0.85;
}

.navSubSectionIcon {
  flex-shrink: 0;
  width: 1.5rem;
  height: 1.5rem;
}

.navSubSectionList {
  padding: 0;
  margin: 0;
  list-style-type: none;
}
</style>
