<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24">
    <title>Icon_24px_SQL_Color</title>
    <g data-name="Product Icons">
      <g>
        <polygon style="fill: #aecbfa" points="4.67 10.44 4.67 13.45 12 17.35 12 14.34 4.67 10.44" />
        <polygon style="fill: #aecbfa" points="4.67 15.09 4.67 18.1 12 22 12 18.99 4.67 15.09" />
        <polygon style="fill: #669df6" points="12 17.35 19.33 13.45 19.33 10.44 12 14.34 12 17.35" />
        <polygon style="fill: #669df6" points="12 22 19.33 18.1 19.33 15.09 12 18.99 12 22" />
        <polygon style="fill: #4285f4" points="19.33 8.91 19.33 5.9 12 2 12 5.01 19.33 8.91" />
        <polygon style="fill: #669df6" points="12 2 4.67 5.9 4.67 8.91 12 5.01 12 2" />
        <polygon style="fill: #aecbfa" points="4.67 5.87 4.67 8.89 12 12.79 12 9.77 4.67 5.87" />
        <polygon style="fill: #669df6" points="12 12.79 19.33 8.89 19.33 5.87 12 9.77 12 12.79" />
      </g>
    </g>
  </svg>
</template>
