<script lang="ts" setup>
import type { NavContext } from '@console/state/vuex/modules/nav';
import type { ValidatorFn } from '@vuelidate/core';

import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import { computed, ref, watch } from 'vue';

import { useVuexStore } from '@console/state/vuex/store';

import Modal from '@shared/design/modals/Modal.vue';
import TextInput from '@shared/design/TextInput.vue';

const show = defineModel<boolean>({ required: true });

const vuexStore = useVuexStore();
const context = computed<NavContext>(() => vuexStore.state.nav.context);
const originalName = computed<string>(() => vuexStore.getters['aws/selectedOrganization']?.friendly_name);

const textbox = ref<HTMLElement | null>(null);
const editableName = ref<string>('');

// When the modal opens, set the editable name to the original name
watch(show, value => {
  if (!value) return;

  editableName.value = originalName.value;
});

const vuelidateState = { editableName, originalName };
const nameHasChanged: ValidatorFn<string, typeof vuelidateState> = (value, siblings) =>
  value !== siblings.originalName.value;

const v$ = useVuelidate(
  {
    editableName: { required, nameHasChanged },
  },
  vuelidateState
);

const setFocusOnPopup = () => textbox.value?.focus();

async function submit() {
  await vuexStore.dispatch('aws/updateOrganizationFriendlyName', {
    awsOrganizationId: context.value.id,
    friendlyName: editableName.value,
  });
  editableName.value = '';
  show.value = false;
}
</script>

<script lang="ts">
export default {
  inheritAttrs: false, // Prevent attributes from assignment to root
  compatConfig: {
    // Needed to allow use of v-model instead of v-model:model-value in compatibility mode
    // See: https://v3-migration.vuejs.org/breaking-changes/v-model
    MODE: 2,
    COMPONENT_V_MODEL: false,
  },
};
</script>

<template>
  <Modal v-model="show" size="lg" :title="`Rename AWS Organization`" @shown="setFocusOnPopup">
    <form @submit.prevent="submit">
      <div class="d-flex justify-center flex-column align-center">
        <TextInput
          id="renameField"
          ref="textbox"
          v-model="editableName"
          class="w-100 mr-2"
          :placeholder="`AWS Organization Name`"
        />
        <div class="pt-4 d-flex flex-row-reverse">
          <BaseButton variant="primary" type="submit" class="rounded-sm" :disabled="v$.$invalid"> Rename </BaseButton>
        </div>
      </div>
    </form>
  </Modal>
</template>
