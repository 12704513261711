<script lang="ts" setup>
import type { ButtonVariant } from '../BaseButton.vue';
import type { BootstrapVariant } from '../bootstrap';
import type { Placement } from '@floating-ui/vue';
import type { HtmlClassAttribute } from 'src/common-types';

import { autoUpdate, flip, offset, shift, useFloating } from '@floating-ui/vue';
import { onClickOutside, onKeyStroke } from '@vueuse/core';
import { nextTick, provide, ref } from 'vue';

import { DROPDOWN_CONTEXT_INJECTION_KEY } from './injection';

interface DropdownProps {
  disabled?: boolean;
  toggleText?: string;

  // Positioning properties
  dropdownPlacement?: Placement;

  // Styling properties
  variant?: ButtonVariant | `outline-${BootstrapVariant}` | 'input';
  size?: 'sm' | 'md' | 'lg';
  noCaret?: boolean;
  toggleClass?: HtmlClassAttribute;
  dropdownClass?: HtmlClassAttribute;
}

const props = withDefaults(defineProps<DropdownProps>(), {
  disabled: false,
  toggleText: undefined,

  dropdownPlacement: 'bottom-end',

  variant: 'primary',
  size: 'md',
  noCaret: false,
  toggleClass: undefined,
  dropdownClass: undefined,
});

const emits = defineEmits<{
  (e: 'shown'): void;
}>();

const toggle = ref<HTMLElement | null>(null);
const dropdown = ref<HTMLElement | null>(null);

const selectedIndex = ref(-1);
const isOpen = ref(false);

function open() {
  isOpen.value = true;
  selectedIndex.value = -1;

  // Wait until the next tick to emit the 'shown' event so that it is sent after the dropdown has been rendered, in
  // case listener needs the dropdown's DOM (e.g. to give focus to an input element)
  nextTick(() => emits('shown'));
}

const { floatingStyles } = useFloating(toggle, dropdown, {
  placement: props.dropdownPlacement,
  middleware: [offset(2), flip(), shift({ padding: 2 })],
  whileElementsMounted: autoUpdate,
});

provide(DROPDOWN_CONTEXT_INJECTION_KEY, { close });

function onToggleClick(event: MouseEvent) {
  // Prevent the default action of the click event (e.g. a parent has an on-click)
  event.preventDefault();

  if (props.disabled) return;

  !isOpen.value ? open() : close();
}

onClickOutside(toggle, event => {
  if (!isOpen.value) return;
  if (dropdown.value?.contains(event.target as Node)) return;

  isOpen.value = false;
});

onKeyStroke(true, event => {
  if (!isOpen.value) return;

  // Find all of the .dropdown-item elements
  const menuItems = Array.from(
    (dropdown.value?.getElementsByClassName('dropdown-item') as HTMLCollectionOf<HTMLElement>) ?? []
  ).filter(isElementSelectableAndVisible);

  // Determine if any are already selected
  const selectedIndex = menuItems.findIndex(i => i.contains(document.activeElement));

  // Handle the key event (navigating up/down, selecting an item, or closing the dropdown)
  if (event.key === 'ArrowDown') {
    const newIndex = Math.min(selectedIndex + 1, menuItems.length - 1);
    menuItems[newIndex]?.focus();
  } else if (event.key === 'ArrowUp') {
    const newIndex = Math.max(selectedIndex - 1, 0);
    menuItems[newIndex]?.focus();
  } else if (event.key === 'Enter' || event.key === ' ') {
    menuItems[selectedIndex]?.click();
  } else if (event.key === 'Escape') {
    isOpen.value = false;
  } else {
    return;
  }

  event.preventDefault();
});

function isElementSelectableAndVisible(element: HTMLElement) {
  // Exclude any items that are disabled (via class or attribute)
  if (element.classList.contains('disabled')) return false;

  const attributes = Array.from(element.attributes);
  if (attributes.some(a => a.name === 'disabled' && a.value !== 'false')) return false;

  // Exclude any items that are hidden (use the bounding box to determine visibility)
  const boundingRect = element.getBoundingClientRect();
  return boundingRect.width > 0 && boundingRect.height > 0;
}

function close() {
  isOpen.value = false;
}
</script>

<template>
  <div class="btn-group">
    <button
      ref="toggle"
      type="button"
      class="btn dropdown-toggle btn-no-focus-box-shadow d-flex align-items-center"
      :class="[`btn-${props.variant}`, `btn-${props.size}`, props.toggleClass, { show: isOpen }]"
      :disabled="props.disabled"
      @click="onToggleClick"
    >
      <span class="flex-grow-1">
        <slot name="toggleContent">
          {{ props.toggleText }}
        </slot>
      </span>
      <BaseIcon v-if="!noCaret" class="ml-2" name="angle-down" />
    </button>

    <div v-if="isOpen" ref="dropdown" class="dropdown-menu show" :class="props.dropdownClass" :style="floatingStyles">
      <slot />
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use '@shared/scss/colors';

// These classnames are defined by Bootstrap
/* stylelint-disable selector-class-pattern */
.dropdown-menu {
  margin: 0;
  overflow: auto;
}

.dropdown-toggle {
  // Don't use the built-in caret, since we're providing a custom one
  &::after {
    display: none;
  }

  // Provide a custom variant which will look like an input field
  &.btn-input {
    color: colors.$gray-700;
    background-color: colors.$white;
    border: 1px solid colors.$gray-400;

    &:not(:disabled):active,
    &:not(:disabled):focus,
    &:not(:disabled):hover {
      color: colors.$gray-900;
      border-color: colors.$gray-500;
    }

    &.show {
      border-color: colors.$gray-500;
    }
  }
}
/* stylelint-enable selector-class-pattern */
</style>
