<script lang="ts" setup>
import type { BootstrapVariant } from '../bootstrap';

import { ref, watch } from 'vue';

import Modal from '@shared/design/modals/Modal.vue';

export interface OkOptions {
  title: string;
  message: string;
  size?: 'sm' | 'md' | 'lg' | 'xl';
  okTitle?: string;
  okVariant?: BootstrapVariant;
}
export interface ConfirmOptions extends OkOptions {
  cancelTitle?: string;
  cancelVariant?: BootstrapVariant | `outline-${BootstrapVariant}`;
}
const defaultOkOptions: Partial<OkOptions> = {
  size: 'md',
  okTitle: 'OK',
  okVariant: 'primary',
};
const defaultConfirmOptions: Partial<ConfirmOptions> = {
  ...defaultOkOptions,
  cancelTitle: 'Cancel',
  cancelVariant: 'outline-info',
};
const initialOptions = ({ title: '', message: '' } as const) as ConfirmOptions;

const isOpen = ref(false);
const showCancel = ref(false);
const options = ref<ConfirmOptions>(initialOptions);
const promiseResolver = ref<((result: boolean) => void) | null>(null);

defineExpose({ showConfirm, showOk, close });

async function showOk(launchOptions: OkOptions): Promise<void> {
  // If a modal is already open, then mark it as closed with a rejection before opening the new one
  if (promiseResolver.value) promiseResolver.value(false);

  options.value = { ...defaultConfirmOptions, ...launchOptions };
  isOpen.value = true;
  showCancel.value = false;

  await new Promise<boolean>(resolve => {
    promiseResolver.value = resolve;
  });
}

function showConfirm(launchOptions: ConfirmOptions): Promise<boolean> {
  // If a modal is already open, then mark it as closed with a rejection before opening the new one
  if (promiseResolver.value) promiseResolver.value(false);

  options.value = { ...defaultConfirmOptions, ...launchOptions };
  isOpen.value = true;
  showCancel.value = true;

  return new Promise<boolean>(resolve => {
    promiseResolver.value = resolve;
  });
}

watch(isOpen, (newValue, oldValue) => {
  // If the modal was closed without a result (e.g. clicked "X" or backdrop), then resolve with false
  if (!newValue && oldValue) close(false);
});

function close(result: boolean) {
  // Close the modal and reset the options
  isOpen.value = false;
  options.value = initialOptions;

  // Resolve the promise with the result
  promiseResolver.value?.(result);
  promiseResolver.value = null;
}
</script>

<template>
  <Modal v-model="isOpen" :title="options.title ?? ''" :size="options.size">
    {{ options.message }}

    <div class="mt-4 text-right">
      <button v-if="showCancel" :class="`btn btn-${options.cancelVariant}`" @click="close(false)">
        {{ options.cancelTitle }}
      </button>
      <button :class="`btn btn-${options.okVariant} ml-3`" @click="close(true)">
        {{ options.okTitle }}
      </button>
    </div>
  </Modal>
</template>
