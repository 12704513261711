<script setup lang="ts">
import { library as fontAwesomeIconLibrary } from '@fortawesome/fontawesome-svg-core';
import {
  faBell,
  faBuilding,
  faCalendarAlt,
  faCalendarCheck,
  faChartBar as faChartBarRegular,
  faComment,
  faCopy as faCopyRegular,
  faLightbulb,
  faQuestionCircle,
  faStickyNote,
  faUser,
} from '@fortawesome/free-regular-svg-icons';
import {
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faAngleDown,
  faAngleUp,
  faArrowDown,
  faArrowLeft,
  faArrowRight,
  faArrowUp,
  faAt,
  faBars,
  faBinoculars,
  faBook,
  faChartBar,
  faChartLine,
  faCheck,
  faCheckCircle,
  faCircle,
  faCircleNotch,
  faCog,
  faCopy,
  faDollarSign,
  faDownload,
  faEnvelope,
  faExchangeAlt,
  faExclamation,
  faExclamationTriangle,
  faExternalLinkAlt,
  faInfoCircle,
  faKey,
  faLayerGroup,
  faLifeRing,
  faLock,
  faMinus,
  faPencilAlt,
  faPhone,
  faPlay,
  faPlus,
  faReceipt,
  faSearch,
  faSearchDollar,
  faSignOutAlt,
  faSitemap,
  faSlidersH,
  faSpinner,
  faStar,
  faStop,
  faSync,
  faTable,
  faTachometerAlt,
  faTimes,
  faTimesCircle,
  faTrash,
  faUserEdit,
  faUserMinus,
  faUsers,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { computed } from 'vue';

const solidIcons = {
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faAngleDown,
  faAngleUp,
  faArrowDown,
  faArrowLeft,
  faArrowRight,
  faArrowUp,
  faAt,
  faBars,
  faBinoculars,
  faBook,
  faChartBar,
  faChartLine,
  faCheck,
  faCheckCircle,
  faCircle,
  faCircleNotch,
  faCog,
  faCopy,
  faDollarSign,
  faDownload,
  faEnvelope,
  faExchangeAlt,
  faExclamation,
  faExclamationTriangle,
  faExternalLinkAlt,
  faInfoCircle,
  faKey,
  faLayerGroup,
  faLifeRing,
  faLock,
  faMinus,
  faPencilAlt,
  faPhone,
  faPlay,
  faPlus,
  faReceipt,
  faSearch,
  faSearchDollar,
  faSignOutAlt,
  faSitemap,
  faSlidersH,
  faSpinner,
  faStar,
  faStop,
  faSync,
  faTable,
  faTachometerAlt,
  faTimes,
  faTimesCircle,
  faTrash,
  faUserEdit,
  faUserMinus,
  faUsers,
};

const regularIcons = {
  faBell,
  faBuilding,
  faCalendarAlt,
  faCalendarCheck,
  faChartBarRegular,
  faComment,
  faCopyRegular,
  faLightbulb,
  faQuestionCircle,
  faStickyNote,
  faUser,
} as const;

fontAwesomeIconLibrary.add(...Object.values(solidIcons), ...Object.values(regularIcons));

// required until all vue components are typescript and we disable no-undef globally
/* eslint-disable no-undef */
type RemoveFaPrefixAndSuffix<T> = RemovePrefix<'fa', RemoveSuffix<'Regular', T>>;
export type RegularIconName = KebabCase<RemoveFaPrefixAndSuffix<keyof typeof regularIcons>>;
export type SolidIconName = KebabCase<RemoveFaPrefixAndSuffix<keyof typeof solidIcons>>;
/* eslint-enable no-undef */

export type BaseIconProps =
  | {
      variant?: 'fas';
      source?: 'font-awesome';
      name: SolidIconName;
    }
  | {
      variant: 'far';
      source?: 'font-awesome';
      name: RegularIconName;
    };

const props = withDefaults(defineProps<BaseIconProps>(), {
  source: 'font-awesome',
  variant: 'fas',
});

const iconObject = computed(() => [props.variant, props.name]);

defineOptions({
  inheritAttrs: false,
});
</script>

<template>
  <FontAwesomeIcon v-if="source === 'font-awesome'" v-bind="$attrs" :icon="iconObject" />
</template>
