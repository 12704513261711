<script lang="ts" setup>
import { inject, ref } from 'vue';
import { useRouter, type RouteLocationRaw } from 'vue-router';

import { DROPDOWN_CONTEXT_INJECTION_KEY } from './injection';

const props = defineProps<{
  disabled?: boolean;
  to?: RouteLocationRaw;

  // If true, the dropdown will not close when the item is clicked
  leaveOpen?: boolean;
}>();
const emits = defineEmits<{
  (e: 'click'): void;
}>();

const dropdown = inject(DROPDOWN_CONTEXT_INJECTION_KEY);

const button = ref<HTMLElement | null>(null);

const router = useRouter();
function handleClick(event: MouseEvent) {
  // Prevent the default action of the click event (e.g. a parent has an on-click)
  event.preventDefault();

  if (props.disabled) return;

  emits('click');

  if (!props.leaveOpen) dropdown?.close();

  if (props.to) router.push(props.to);
}
</script>

<template>
  <button ref="button" type="button" class="dropdown-item" :disabled="disabled" @click="handleClick">
    <slot />
  </button>
</template>
