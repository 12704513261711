<script>
import _ from 'lodash';
import { mapState } from 'pinia';
import { mapGetters as vuexMapGetters, mapState as vuexMapState } from 'vuex';

import { useAzureStore } from '@azure/state';
import { useAuthStore, Cloud, PermissionLevel } from '@shared/state/auth.store';
import { useFeatureStore } from '@shared/state/feature.store';

import ContextSelectorHeader from './ContextSelectorHeader.vue';
import ContextSelectorLink from './ContextSelectorLink.vue';
import ContextSelectorToggle from './ContextSelectorToggle.vue';
import RenameAwsOrganizationModal from './RenameAwsOrganizationModal.vue';

export default {
  components: {
    ContextSelectorHeader,
    ContextSelectorLink,
    ContextSelectorToggle,
    RenameAwsOrganizationModal,
  },
  props: {
    mobile: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      editableAwsOrganization: null,
      editableName: '',
      contextSearch: '',
      showRenameModal: false,
    };
  },
  computed: {
    ...vuexMapGetters('customer', ['selectedCompany', 'isDemo', 'isSettingEnabled']),
    ...vuexMapGetters('aws', ['selectedOrganization', 'isNewOrganization', 'isMultiOrgSummarySelected']),
    ...vuexMapGetters('nav', ['isAwsContext', 'isAzureContext', 'isGcpContext', 'isOnboarding', 'context']),
    ...vuexMapGetters('gcp', ['billingAccounts', 'getBillingAccountById']),
    ...vuexMapState('aws', ['awsOrganizations']),
    ...mapState(useAuthStore, ['role', 'isGranularUser', 'isAtLeastRoleForCustomerOrGranular']),
    ...mapState(useFeatureStore, ['featureFlags']),
    ...mapState(useAzureStore, ['billingScopes', 'getBillingScopeById']),
    selectedBillingAccount() {
      return this.getBillingAccountById(this.context?.id);
    },
    contextToggleText() {
      if (this.isAwsContext) {
        return this.selectedOrganization?.friendly_name;
      } else if (this.isGcpContext) {
        return this.selectedBillingAccount?.display_name;
      }

      const scope = this.getBillingScopeById(this.context?.id);
      return scope?.display_name;
    },
    contextToggleSubtext() {
      if (this.isAwsContext) {
        return this.selectedOrganization?.management_aws_account_number;
      } else if (this.isGcpContext) {
        return this.selectedBillingAccount?.billing_account_id;
      }

      const scope = this.getBillingScopeById(this.context?.id);
      return scope?.billing_profile_id ?? scope?.billing_account_id;
    },
    selectableAwsOrganizations() {
      const filteredOrganizations = _(this.awsOrganizations)
        .values()
        .filter(o => this.isNewOrganization || o.id !== this.selectedOrganization?.id)
        .map(o => ({
          ...o,
          status_sort: o.status === 'Active' ? 0 : 1,
          friendly_name_sort: o.friendly_name.toLowerCase(),
        }))
        .sortBy(['status_sort', 'friendly_name_sort'])
        .value();
      const activeOrgCount = _.reduce(
        this.awsOrganizations,
        (count, organization) => {
          return count + (organization.status === 'Active' ? 1 : 0);
        },
        0
      );

      if (!this.isMultiOrgSummarySelected && activeOrgCount > 1 && !this.isGranularUser(this.selectedCompany.id)) {
        return [
          {
            id: 'MULTI_ORG_SUMMARY',
            friendly_name: 'All Organizations',
            status: 'Active',
            data_source: 'CostAndUsageReport',
          },
          ...filteredOrganizations,
        ];
      }

      return filteredOrganizations;
    },
    awsOrganizationSearchResults() {
      return _.filter(this.selectableAwsOrganizations, o => {
        if (!this.contextSearch) {
          return true;
        }

        const search = this.contextSearch.toLowerCase();
        const fieldsToSearch = _.pick(o, ['friendly_name', 'management_aws_account_number']);
        return _.some(fieldsToSearch, value => value.toLowerCase().includes(search));
      });
    },
    selectableBillingAccounts() {
      return _.chain(this.billingAccounts)
        .filter(ba => ba.id !== this.context?.id)
        .sortBy(['display_name'])
        .value();
    },
    billingAccountSearchResults() {
      return _.filter(this.selectableBillingAccounts, ba => {
        if (!this.contextSearch) {
          return true;
        }

        const search = this.contextSearch.toLowerCase();
        const fieldsToSearch = _.pick(ba, ['display_name', 'billing_account_id']);
        return _.some(fieldsToSearch, value => value.toLowerCase().includes(search));
      });
    },
    selectableBillingScopes() {
      return _.chain(this.billingScopes)
        .filter(scope => scope.id !== this.context?.id)
        .sortBy(['display_name'])
        .value();
    },
    billingScopeSearchResults() {
      return _.filter(this.selectableBillingScopes, ba => {
        if (!this.contextSearch) {
          return true;
        }

        const search = this.contextSearch.toLowerCase();
        const fieldsToSearch = _.pick(ba, ['display_name', 'billing_account_id', 'billing_profile_id']);
        return _.some(fieldsToSearch, value => value.toLowerCase().includes(search));
      });
    },
    hasSelectableContextItems() {
      return (
        this.selectableAwsOrganizations.length > 0 ||
        this.selectableBillingAccounts.length > 0 ||
        this.selectableBillingScopes.length > 0
      );
    },
    isViewer() {
      return this.role(this.selectedCompany.id) === 'Viewer';
    },
    isAwsOnboarding() {
      return this.isAwsContext && this.isOnboarding;
    },
    isAzureOnboarding() {
      return this.isAzureContext && this.isOnboarding;
    },
    isGcpOnboarding() {
      return this.isGcpContext && this.isOnboarding;
    },
    showRenameOrganizationLink() {
      return (
        this.selectedOrganization &&
        !this.isMultiOrgSummarySelected &&
        this.isAtLeastRoleForCustomerOrGranular(
          this.selectedCompany.id,
          PermissionLevel.Editor,
          Cloud.AWS,
          this.selectedOrganization.id
        )
      );
    },
    showCreateAwsOrganizationLink() {
      return !this.isViewer && !this.isAwsOnboarding && !this.isGranularUser(this.selectedCompany.id);
    },
    showCreateAzureBillingScopeLink() {
      return !this.isViewer && !this.isAzureOnboarding && !this.isGranularUser(this.selectedCompany.id);
    },
    showCreateGcpBillingAccountLink() {
      return !this.isViewer && !this.isGcpOnboarding && !this.isGranularUser(this.selectedCompany.id);
    },
  },
  methods: {
    showEditPopup() {
      if (!this.isDemo) {
        this.showRenameModal = true;
      }
    },
    resetSearch() {
      this.contextSearch = '';
      this.$refs.contextSearch && this.$refs.contextSearch.focus();
    },
    status(awsOrganization) {
      return awsOrganization.status === 'Active' ? 'success' : 'warning';
    },
    isMultiOrgSummary(awsOrganization) {
      return awsOrganization.id === 'MULTI_ORG_SUMMARY';
    },
  },
};
</script>

<template>
  <div>
    <div v-if="!hasSelectableContextItems && isViewer">
      <ContextSelectorToggle
        class="contextSelectorToggle mt-2 mx-2 py-3"
        :class="{ mobile }"
        :text="contextToggleText"
        :subtext="contextToggleSubtext"
        :cloud="context?.cloud"
        readonly
      />
    </div>
    <b-dropdown
      v-else
      toggle-class="btn-no-focus-box-shadow border-0 flex-shrink-0 mt-2 mx-2 p-0"
      menu-class="rounded-sm"
      class="w-100 py-3"
      variant="transparent"
      offset="10"
      boundary="window"
      no-caret
      @shown="resetSearch()"
    >
      <template #button-content>
        <ContextSelectorToggle
          v-if="isAwsOnboarding"
          class="contextSelectorToggle"
          :class="{ mobile }"
          :cloud="context.cloud"
        >
          (New Organization)
        </ContextSelectorToggle>
        <ContextSelectorToggle
          v-else-if="isAzureOnboarding"
          class="contextSelectorToggle"
          :class="{ mobile }"
          :cloud="context.cloud"
        >
          (New Billing Scope)
        </ContextSelectorToggle>
        <ContextSelectorToggle
          v-else-if="isGcpOnboarding"
          class="contextSelectorToggle"
          :class="{ mobile }"
          :cloud="context?.cloud"
        >
          (New Billing Account)
        </ContextSelectorToggle>
        <ContextSelectorToggle
          v-else
          class="contextSelectorToggle"
          :class="{ mobile }"
          :text="contextToggleText"
          :subtext="contextToggleSubtext"
          :cloud="context?.cloud"
        />
      </template>
      <div v-if="hasSelectableContextItems" class="contextSelectorSearch">
        <input
          ref="contextSearch"
          v-model="contextSearch"
          type="text"
          class="form-control contextSelectorSearchInput"
          placeholder="Search"
        />
        <BaseIcon class="contextSelectorSearchIcon" name="search" />
      </div>
      <div class="contextSelectorItems">
        <b-dropdown-group v-if="awsOrganizationSearchResults.length > 0">
          <ContextSelectorHeader cloud="aws" text="Amazon Web Services" />
          <ContextSelectorLink
            v-for="awsOrganization in awsOrganizationSearchResults"
            :id="awsOrganization.id"
            :key="awsOrganization.id"
            :text="awsOrganization.friendly_name"
            :subtext="awsOrganization.management_aws_account_number"
            :status="awsOrganization.status"
            cloud="aws"
          >
            <template v-if="isMultiOrgSummary(awsOrganization)" #icon><span /></template>
          </ContextSelectorLink>
        </b-dropdown-group>
        <b-dropdown-group v-if="billingScopeSearchResults.length > 0">
          <ContextSelectorHeader cloud="azure" text="Microsoft Azure" />
          <ContextSelectorLink
            v-for="billing_scope in billingScopeSearchResults"
            :id="billing_scope.id"
            :key="billing_scope.id"
            :text="billing_scope.display_name"
            :subtext="billing_scope.billing_profile_id || billing_scope.billing_account_id"
            :status="billing_scope.status"
            cloud="azure"
          />
        </b-dropdown-group>
        <b-dropdown-group v-if="billingAccountSearchResults.length > 0">
          <ContextSelectorHeader cloud="gcp" text="Google Cloud" />
          <ContextSelectorLink
            v-for="billingAccount in billingAccountSearchResults"
            :id="billingAccount.id"
            :key="billingAccount.id"
            :text="billingAccount.display_name"
            :subtext="billingAccount.billing_account_id"
            :status="billingAccount.status"
            cloud="gcp"
          />
        </b-dropdown-group>
      </div>
      <b-dropdown-divider
        v-if="
          (showRenameOrganizationLink ||
            showCreateAwsOrganizationLink ||
            showCreateAzureBillingScopeLink ||
            showCreateGcpBillingAccountLink) &&
          hasSelectableContextItems
        "
      ></b-dropdown-divider>
      <a
        v-if="showRenameOrganizationLink"
        href="#"
        :disabled="isDemo"
        class="link renameOrganization"
        :class="{ disabled: isDemo }"
        @click.prevent="showEditPopup()"
      >
        <div class="icon"><BaseIcon name="pencil-alt" class="contextSelectorIcon" /></div>
        <div class="content">Rename Organization</div>
      </a>
      <ContextSelectorLink
        v-if="showCreateAwsOrganizationLink"
        id="NEW_ORGANIZATION_PLACEHOLDER"
        cloud="aws"
        :disabled="isDemo"
      >
        Add AWS Organization
        <template #icon><BaseIcon name="plus" class="contextSelectorIcon" /></template>
      </ContextSelectorLink>
      <ContextSelectorLink
        v-if="showCreateAzureBillingScopeLink"
        id="NEW_BILLING_SCOPE_PLACEHOLDER"
        cloud="azure"
        :disabled="isDemo"
      >
        Add Azure Billing Scope
        <template #icon><BaseIcon name="plus" class="contextSelectorIcon" /></template>
      </ContextSelectorLink>
      <ContextSelectorLink
        v-if="showCreateGcpBillingAccountLink"
        id="NEW_BILLING_ACCOUNT_PLACEHOLDER"
        cloud="gcp"
        :disabled="isDemo"
      >
        Add Google Cloud Billing Account
        <template #icon><BaseIcon name="plus" class="contextSelectorIcon" /></template>
      </ContextSelectorLink>
    </b-dropdown>
    <RenameAwsOrganizationModal v-model="showRenameModal" />
  </div>
</template>

<style lang="scss">
@import '@shared/scss/colors.scss';

.awsOrganizationDropDownItems {
  max-height: 300px;
  overflow-y: auto;
}

.awsOrganizationDropDownMenu {
  top: -10px !important;
}

.contextSelectorIcon {
  display: block;
  width: 20px;
  height: 20px;
  margin: 0 auto;
  color: map-get($theme-colors, 'primary');
}
</style>

<style lang="scss" scoped>
@import 'bootstrap/scss/_functions.scss';
@import 'bootstrap/scss/_variables.scss';
@import 'bootstrap/scss/mixins/_breakpoints.scss';
@import '@shared/scss/colors.scss';

.contextSelectorToggle {
  max-width: calc(295px - 1.5rem - 1px);
  padding: 0 0.5rem;

  &.mobile {
    max-width: none;
  }
}

.menuListItem {
  display: flex;
  align-items: center;
  width: 320px;
  min-height: 50px;

  > a > div:first-child {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 25px;
  }
}

.menuListItem:hover {
  background-color: #e6e6e6;
}

.contextSelectorSearch {
  position: relative;
  padding: 0.5rem 0.5rem;
  margin-top: -0.5rem;
  background-color: $white;
}

.contextSelectorItems {
  max-height: 300px;
  overflow-y: auto;
}

.contextSelectorSearchIcon {
  position: absolute;
  top: calc((1.5em + 0.75rem + 2px) / 2);
  left: 1.25rem;
  margin-top: 1px;
  color: $gray-600;
}

.contextSelectorSearchInput {
  padding-left: calc(1em + 1.25rem);
}

.icon {
  flex-basis: 1.5rem;
  min-width: 1.5rem;
  margin-right: 0.5rem;

  > svg {
    display: block;
    margin: 0 auto;
  }
}

.content {
  flex-grow: 1;
}

.link {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 20rem;
  min-height: 3rem;
  padding: 0.25rem 0.5rem;
  color: $body-color;
  text-decoration: none;

  &.disabled {
    pointer-events: none;
    cursor: not-allowed;
    opacity: 0.5;
  }
}

.link:active,
.link:focus,
.link:hover {
  background-color: $gray-200;
  outline: 0;
}
</style>
