<script setup lang="ts">
import type { FeatureFlag } from '@shared/state/feature.store';

import { computed } from 'vue';

import { useVuexStore } from '@console/state/vuex/store';
import { useAuthStore } from '@shared/state/auth.store';
import { useFeatureStore } from '@shared/state/feature.store';
import env from '@shared/utilities/env';

import Dropdown from '@shared/design/dropdowns/Dropdown.vue';
import DropdownContent from '@shared/design/dropdowns/DropdownContent.vue';
import DropdownDivider from '@shared/design/dropdowns/DropdownDivider.vue';
import DropdownItem from '@shared/design/dropdowns/DropdownItem.vue';

const vuexStore = useVuexStore();
const authStore = useAuthStore();
const isDemo = computed(() => vuexStore.getters['customer/isDemo']);

const isLocal = env.isLocal();
const isVisible = computed(() => {
  return flags.value.length && (isLocal || (authStore.isEmployee && !isDemo.value));
});

const featureStore = useFeatureStore();
const flags = computed(() =>
  Object.entries(featureStore.featureFlags).map(([key, value]) => ({
    name: key as FeatureFlag,
    value: value as boolean,
  }))
);

const activeFlagCount = computed(() => flags.value.filter(flag => flag.value).length);
const showActiveIndicator = computed(() => activeFlagCount.value > 0);
const activeIndicator = computed(() => (activeFlagCount.value > 9 ? '+' : activeFlagCount.value));

const onChange = (name: FeatureFlag) => {
  featureStore.toggleFeatureFlag(name);
};

const areAllFlagsEnabled = computed(() => flags.value.every(flag => flag.value));
const toggleAll = () => {
  featureStore.toggleAllFeatureFlags();
};
</script>

<template>
  <Dropdown
    v-if="isVisible && !isDemo"
    variant="transparent"
    data-testid="feature-flag-container"
    dropdown-placement="bottom-end"
    toggle-class="p-0"
    dropdown-class="mt-1"
    no-caret
  >
    <template #toggleContent>
      <div class="d-flex position-relative" data-testid="feature-flag-button">
        <BaseIcon name="cog" class="icon text-muted" />
        <div v-if="showActiveIndicator" class="activeFlagCount">{{ activeIndicator }}</div>
      </div>
    </template>

    <DropdownContent>
      <h3>Feature Flags</h3>
    </DropdownContent>
    <template v-if="flags.length > 1">
      <DropdownItem class="toggleAll" leave-open @click="toggleAll">
        <div class="custom-control custom-switch">
          <input
            id="feature-flag-all"
            :checked="areAllFlagsEnabled"
            class="custom-control-input"
            type="checkbox"
            @change="toggleAll"
          />
          <label class="custom-control-label" for="feature-flag-all"> All </label>
        </div>
      </DropdownItem>
      <DropdownDivider />
    </template>
    <DropdownItem v-for="flag in flags" :key="flag.name" leave-open @click="onChange(flag.name)">
      <div class="custom-control custom-switch">
        <input
          :id="`feature-flag-${flag.name}`"
          :checked="flag.value"
          class="custom-control-input"
          type="checkbox"
          @change="onChange(flag.name)"
        />
        <label class="custom-control-label" :for="`feature-flag-${flag.name}`">
          {{ flag.name }}
        </label>
      </div>
    </DropdownItem>
  </Dropdown>
</template>

<style scoped lang="scss">
@import '@shared/scss/colors.scss';

.icon {
  width: 1.25rem;
  height: 1.25rem;
}

.activeFlagCount {
  position: absolute;
  right: -6px;
  bottom: -6px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15px;
  height: 15px;
  font-size: 10px;
  font-weight: 600;
  color: white;
  background-color: var(--danger);
  border-radius: 50%;
}
</style>
