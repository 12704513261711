<script>
import NewIndicator from '@console/components/navigation/NewIndicator.vue';
import AwsAccountsIcon from '@shared/design/icons/AwsAccountsIcon.vue';
import AwsElasticacheIcon from '@shared/design/icons/AwsElasticacheIcon.vue';
import AwsMemorydbIcon from '@shared/design/icons/AwsMemorydbIcon.vue';
import AwsOpensearchIcon from '@shared/design/icons/AwsOpensearchIcon.vue';
import AwsRdsIcon from '@shared/design/icons/AwsRdsIcon.vue';
import AwsRedshiftIcon from '@shared/design/icons/AwsRedshiftIcon.vue';
import BillingIcon from '@shared/design/icons/BillingIcon.vue';
import CommitmentsIcon from '@shared/design/icons/CommitmentsIcon.vue';
import GoogleCloudSqlIcon from '@shared/design/icons/GoogleCloudSQLIcon.vue';
import OnboardingIcon from '@shared/design/icons/OnboardingIcon.vue';
import PrepaymentsIcon from '@shared/design/icons/PrepaymentsIcon.vue';
import SavingsIcon from '@shared/design/icons/SavingsIcon.vue';
import ShowbackIcon from '@shared/design/icons/ShowbackIcon.vue';
import UserIcon from '@shared/design/icons/UserIcon.vue';

export default {
  components: {
    AwsAccountsIcon,
    AwsElasticacheIcon,
    AwsMemorydbIcon,
    AwsOpensearchIcon,
    AwsRdsIcon,
    AwsRedshiftIcon,
    BillingIcon,
    CommitmentsIcon,
    OnboardingIcon,
    SavingsIcon,
    UserIcon,
    PrepaymentsIcon,
    ShowbackIcon,
    NewIndicator,
    GoogleCloudSqlIcon,
  },
  props: {
    icon: {
      type: String,
      required: false,
      default: null,
    },
    variant: {
      type: String,
      required: false,
      default: 'fas',
    },
    customIcon: {
      type: Boolean,
      required: false,
      default: false,
    },
    isNew: {
      type: Boolean,
      required: false,
      default: false,
    },
    isEarlyAccess: {
      type: Boolean,
      required: false,
      default: false,
    },
    isOptimizable: {
      type: Boolean,
      required: false,
      default: false,
    },
    indent: {
      type: Boolean,
      required: false,
      default: false,
    },
    compact: {
      type: Boolean,
      required: false,
      default: false,
    },
    iconStyleOverrides: {
      type: Object,
      required: false,
      default: () => ({}),
    },
  },
  computed: {
    showIcon() {
      return !!this.icon;
    },
    config() {
      return this.customIcon
        ? { is: this.icon, props: { class: 'icon' }, style: this.iconStyleOverrides }
        : {
            is: 'BaseIcon',
            props: {
              name: this.icon,
              class: 'icon iconFa',
              'fixed-width': true,
              variant: this.variant,
              style: this.iconStyleOverrides,
            },
          };
    },
  },
};
</script>

<template>
  <router-link v-bind="$attrs" class="routerLink" active-class="activeLink">
    <div class="linkItem" :class="{ indent, compact }">
      <component :is="config.is" v-if="showIcon" v-bind="config.props" />
      <slot />
      <div class="d-flex flex-grow-1 justify-content-end">
        <NewIndicator v-if="isNew" class="indicatorBadge" />
        <NewIndicator v-else-if="isEarlyAccess" label="Early Access" class="indicatorBadge" />
        <NewIndicator v-else-if="isOptimizable" label="Optimize" class="indicatorBadge" variant="success" />
      </div>
    </div>
  </router-link>
</template>

<style scoped>
.linkItem {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0.5rem 1rem;
  color: rgba(255, 255, 255, 0.85);
}

.linkItem.indent {
  padding-left: 3.25rem;
}

.linkItem.compact {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.routerLink {
  text-decoration: none;
  opacity: 0.85;
  transition: opacity 0.1s;
}

.activeLink,
.routerLink:hover {
  position: relative;
  display: block;
  background: rgba(0, 0, 0, 0.15);
  opacity: 1;
}

.activeLink::before,
.routerLink:hover::before {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 4px;
  height: 100%;
  content: '';
  background-color: #5c54ff;
}

.icon {
  flex-shrink: 0;
  width: 1.5rem;
  height: 1.5rem;
  margin-right: 1rem;
  color: #a5a4bf;

  .linkItem.compact & {
    width: 1.25rem;
    height: 1.25rem;
  }
}

.iconFa {
  width: 1.25rem;
  height: 1.25rem;
}

.icon .activeLink .icon {
  color: #a7a3ff;
}

.indicatorBadge {
  margin-left: 0.75rem;
}
</style>
