<script lang="ts" setup>
import type { RouteLocationRaw } from 'vue-router';

import DropdownItem from '@shared/design/dropdowns/DropdownItem.vue';

const props = defineProps<{
  to?: RouteLocationRaw;
  leaveOpen?: boolean;
}>();

const emits = defineEmits<{
  (e: 'click'): void;
}>();
</script>

<template>
  <DropdownItem
    class="p-0"
    link-class="profile-dropdown"
    :to="props.to"
    :leave-open="leaveOpen"
    @click="emits('click')"
  >
    <div class="profileDropdownItem d-flex pt-2 pb-2 align-items-center">
      <div class="ml-2 mr-2 d-flex justify-content-center">
        <slot name="left" />
      </div>
      <div class="pl-1 text-truncate text-nowrap">
        <slot name="right" />
      </div>
    </div>
  </DropdownItem>
</template>

<style lang="scss" scoped>
.profileDropdownItem {
  > div:first-child {
    width: 28px;
  }

  > div:last-child {
    width: 172px;
  }
}
</style>
