<script setup lang="ts">
import type { TooltipSize } from './Tooltip.vue';

import { computed, useSlots } from 'vue';

import Tooltip, { TooltipStub } from './Tooltip.vue';

defineProps<{
  tooltipClass?: string;
  size?: TooltipSize;
}>();

const slots = useSlots();
const hasTooltip = computed(() => !!slots.tooltip);
</script>

<script lang="ts">
export const TextTooltipStub = TooltipStub; // The same stub can be used for this component too
</script>

<!-- Using display: inline-block removes leading and trailing spaces, which ensures that the dashed underline isn't applied to spaces -->
<template>
  <Tooltip
    placement="top"
    :tooltip-class="tooltipClass"
    :size="size"
    class="d-inline-block"
    :class="{ 'tooltip-target': hasTooltip }"
  >
    <slot />

    <template v-if="hasTooltip" #tooltip>
      <slot name="tooltip" />
    </template>
  </Tooltip>
</template>
