const lastChars = (str, n = 4) => str.slice(str.length - n);

const generateFromId = (prefix, id) => {
  return `${prefix} ${lastChars(id)}`;
};

export const redact = str => {
  return 'X'.repeat(str.length);
};

export const redactWithLimit = (str, limit) => {
  return redact(str.slice(0, limit));
};

export const generateCustomerName = customerId => {
  return generateFromId('Cust', customerId);
};

export const generateAwsOrganizationName = organizationId => {
  return generateFromId('AWS Organization', organizationId);
};

export const generateAwsAccountName = accountId => {
  return generateFromId('AWS Account', accountId);
};

export const generateGcpBillingAccountDisplayName = billingAccountId => {
  return generateFromId('Google Cloud Billing Account', billingAccountId);
};

export const generateGcpBillingAccountName = () => {
  return `${'X'.repeat(6)}-${'X'.repeat(6)}-${'X'.repeat(6)}`;
};

export const generatAzureBillingAccountDisplayName = billingAccountId => {
  return generateFromId('Azure Billing Account', billingAccountId);
};

export const generateAzureBillingAccountName = () => {
  return `${'X'.repeat(4)}-${'X'.repeat(4)}-${'X'.repeat(3)}-${'X'.repeat(3)}`;
};

export const generateAzureSubscription = () => {
  return `${'X'.repeat(8)}-${'X'.repeat(4)}-${'X'.repeat(4)}-${'X'.repeat(4)}-${'X'.repeat(8)}`;
};

export const generateAzureResourceGroup = () => {
  return 'XXXXXXXXX';
};
