<script setup>
import { computed } from 'vue';

import { useAzureStore } from '@azure/state';
import { useVuexStore } from '@console/state/vuex/store';

import NavigationHeader from '@console/components/navigation/NavigationHeader.vue';
import NavigationLink from '@console/components/navigation/NavigationLink.vue';
import NavigationList from '@console/components/navigation/NavigationList.vue';
import NavigationSection from '@console/components/navigation/NavigationSection.vue';
import NewIndicator from '@console/components/navigation/NewIndicator.vue';

const azureState = useAzureStore();
const vuexStore = useVuexStore();

const isBillingScopeActive = computed(() => {
  const context = vuexStore.getters['nav/context'];
  return azureState.isBillingScopeActive(context.id);
});
</script>

<template>
  <nav v-if="isBillingScopeActive">
    <div>
      <NavigationHeader>Autonomous Discount Management</NavigationHeader>
      <NavigationSection icon="azure-virtual-machines-icon" data-test="nav-section-compute">
        <template #header
          >Compute
          <NewIndicator label="Early Access" class="earlyAccessBadge" />
        </template>
        <template #items>
          <li>
            <NavigationLink
              icon="savings-icon"
              :to="{ name: 'azure_compute_savings' }"
              custom-icon
              indent
              compact
              data-testid="azure-savings-link"
            >
              Savings
            </NavigationLink>
          </li>
          <li>
            <NavigationLink
              icon="cog"
              :to="{ name: 'azure_compute_settings' }"
              indent
              compact
              data-testid="azure-savings-link"
            >
              Settings
            </NavigationLink>
          </li>
        </template>
      </NavigationSection>
    </div>
  </nav>
  <nav v-else>
    <NavigationList>
      <li>
        <NavigationLink
          icon="onboarding-icon"
          :to="{ name: 'azure_onboarding' }"
          custom-icon
          data-testid="azure-onboarding-link"
        >
          Onboarding
          <NewIndicator label="Early Access" class="earlyAccessBadge" />
        </NavigationLink>
      </li>
    </NavigationList>
  </nav>
</template>

<style lang="scss" scoped>
.earlyAccessBadge {
  margin-left: 0.75rem;
}
</style>
