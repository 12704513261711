import { core as api } from '@shared/services/api';

export const provisionBillingAccount = async billingAccount => {
  const res = await api.post(`/v1/gcp_billing_accounts`, billingAccount);
  return res.data;
};

export const activateBillingAccount = async billingAccountId => {
  const res = await api.post(`/v1/gcp_billing_accounts/${billingAccountId}/activate`, billingAccountId);
  return res.data;
};

export const listBillingAccounts = async () => {
  const res = await api.get(`/v1/gcp_billing_accounts`);
  return res.data;
};

export const getBillingAccountSettings = async billingAccountId => {
  const res = await api.get(`/v1/gcp_billing_accounts/${billingAccountId}/settings_summary`);
  return res.data;
};

export const getSavingsDashboard = async (billingAccountId, timeframe) => {
  try {
    const res = await api.get(`/v1/gcp_billing_accounts/${billingAccountId}/savings_dashboard`, {
      params: { timeframe },
    });
    return res.data;
  } catch (e) {
    if (e.response?.status === 404) {
      return null;
    }

    throw e;
  }
};

export const getCloudSQLSavingsDashboard = async (billingAccountId, timeframe) => {
  // dummy data from ProsperOps test account until backend is done
  return {
    id: '7af911ba-0fd8-4e8a-8750-2bbcd97d300e',
    billing_account_id: '3fc79fd3-b918-4ed4-94e6-e7122ad5ed58',
    selected_timeframe: {
      key: '2025-02',
      friendly_name: 'February 2025',
    },
    available_timeframes: [
      {
        key: '2025-02',
        friendly_name: 'February 2025',
      },
      {
        key: '2025-01',
        friendly_name: 'January 2025',
      },
      {
        key: '2024-12',
        friendly_name: 'December 2024',
      },
      {
        key: '2024-11',
        friendly_name: 'November 2024',
      },
      {
        key: '2024-10',
        friendly_name: 'October 2024',
      },
      {
        key: '2024-09',
        friendly_name: 'September 2024',
      },
      {
        key: '2024-08',
        friendly_name: 'August 2024',
      },
      {
        key: '2024-07',
        friendly_name: 'July 2024',
      },
      {
        key: '2024-06',
        friendly_name: 'June 2024',
      },
      {
        key: '2024-05',
        friendly_name: 'May 2024',
      },
      {
        key: '2024-04',
        friendly_name: 'April 2024',
      },
      {
        key: '2024-03',
        friendly_name: 'March 2024',
      },
    ],
    dashboard: {
      version: 11,
      is_finalized: false,
      data_through_date: '2025-02-09T23:59:59Z',
      savings_summary: {
        list_cost: 12.205036,
        negotiated_savings: 0,
        committed_use_discount_savings: 0.960469,
        flexible_committed_use_discount_savings: 4.32,
        sustained_use_discount_savings: 0.126526,
        other_credits: 0,
        total_spend: 6.798041,
        total_savings: 1.331344,
        currency_code: 'USD',
        currency_conversion_rate: 1,
        skus: [
          '51E2-59BD-7A6E',
          'ACBC-6999-A1C4',
          'BE48-3D79-88EE',
          '4AE2-EBC5-070A',
          'E35D-222F-E443',
          '2F6C-EB97-C718',
          '06E8-B15F-3E83',
          '5AA0-D2CC-1DA8',
          '3080-B456-59FC',
          'F61D-4D51-AAFC',
          '6723-40D7-8BDC',
        ],
      },
      effective_savings_rate: {
        current: 0.16376933802495514,
        previous: -0.2696903339547935,
      },
      key_metrics: {
        action_count: 2,
        committed_use_discount_utilization: 0.4001151311055577,
        flexible_committed_use_discount_utilization: 0.989999999962963,
      },
      savings_breakdown_periods: [
        {
          month_start: '2024-12-01T00:00:00Z',
          period_title: 'Dec 2024',
          is_finalized: true,
          sort_order: 0,
          gross_savings: -15.527684,
          total_cud_savings: -15.527684,
          total_other_savings: 0,
          on_demand_savings: 0,
          sud_savings: 0,
          free_tier_savings: 0,
          other_savings: 0,
          compute_engine_usage: 0,
          compute_engine_spend: 15.527684,
          base_savings: -4.016856,
          smart_savings: -9.461583,
          inherited_savings: -2.049245,
          unbilled_savings: 0,
          savings_share: 0,
          estimated_savings_share: 0,
          net_savings: -15.527684,
          gross_savings_normalized: -15.026790967741935,
          compute_engine_usage_normalized: 0,
          compute_engine_spend_normalized: 15.026790967741935,
          savings_share_normalized: 0,
          net_savings_normalized: -15.026790967741935,
          base_savings_normalized: -3.88728,
          smart_savings_normalized: -9.15637064516129,
          inherited_savings_normalized: -1.9831403225806452,
          unbilled_savings_normalized: 0,
          total_cud_savings_normalized: -15.026790967741935,
          total_other_savings_normalized: 0,
          on_demand_savings_normalized: 0,
          sud_savings_normalized: 0,
          free_tier_savings_normalized: 0,
          other_savings_normalized: 0,
        },
        {
          month_start: '2025-01-01T00:00:00Z',
          period_title: 'Jan 2025',
          is_finalized: true,
          sort_order: 1,
          gross_savings: -0.028179,
          total_cud_savings: -1.522822,
          total_other_savings: 1.494643,
          on_demand_savings: 0,
          sud_savings: 1.494643,
          free_tier_savings: 0,
          other_savings: 0,
          compute_engine_usage: 21.114735,
          compute_engine_usage_delta: 20.43,
          compute_engine_spend: 21.142914,
          base_savings: 1.5931440000600927,
          smart_savings: -2.0504701159931384,
          inherited_savings: -1.0654958840669542,
          unbilled_savings: 0,
          savings_share: 0,
          estimated_savings_share: 0,
          net_savings: -0.028179,
          net_savings_delta: 15,
          net_savings_delta_percentage: -99.8,
          gross_savings_normalized: -0.02727,
          compute_engine_usage_normalized: 20.433614516129033,
          compute_engine_spend_normalized: 20.46088451612903,
          savings_share_normalized: 0,
          net_savings_normalized: -0.02727,
          base_savings_normalized: 1.5417522581226704,
          smart_savings_normalized: -1.9843259187030373,
          inherited_savings_normalized: -1.0311250490970523,
          unbilled_savings_normalized: 0,
          total_cud_savings_normalized: -1.4736987096774194,
          total_other_savings_normalized: 1.4464287096774193,
          on_demand_savings_normalized: 0,
          sud_savings_normalized: 1.4464287096774193,
          free_tier_savings_normalized: 0,
          other_savings_normalized: 0,
        },
        {
          month_start: '2025-02-01T00:00:00Z',
          period_title: 'Feb 2025',
          is_finalized: false,
          sort_order: 2,
          gross_savings: 1.331344,
          total_cud_savings: 1.204818,
          total_other_savings: 0.126526,
          on_demand_savings: 0,
          sud_savings: 0.126526,
          free_tier_savings: 0,
          other_savings: 0,
          compute_engine_usage: 8.129385,
          compute_engine_spend: 6.798041,
          base_savings: 0.993816,
          smart_savings: 0.373791,
          inherited_savings: -0.162789,
          unbilled_savings: 0,
          estimated_savings_share: 0,
          gross_savings_normalized: 1.42644,
          compute_engine_usage_normalized: 8.710055357142856,
          compute_engine_spend_normalized: 7.283615357142857,
          base_savings_normalized: 1.0648028571428572,
          smart_savings_normalized: 0.40049035714285713,
          inherited_savings_normalized: -0.1744167857142857,
          unbilled_savings_normalized: 0,
          total_cud_savings_normalized: 1.2908764285714285,
          total_other_savings_normalized: 0.13556357142857142,
          on_demand_savings_normalized: 0,
          sud_savings_normalized: 0.13556357142857142,
          free_tier_savings_normalized: 0,
          other_savings_normalized: 0,
        },
      ],
      has_contract_pricing: false,
      is_effective_savings_rate_adjusted: false,
      daily_savings: [
        {
          day: 1,
          period_title: 'Feb 01',
          base_savings: 0.110423999995,
          smart_savings: 0.041519000005,
          inherited_savings: -0.018087,
          unbilled_savings: 0,
          total_cud_savings: 0.133856,
          total_other_savings: 0,
        },
        {
          day: 2,
          period_title: 'Feb 02',
          base_savings: 0.11042399999,
          smart_savings: 0.04155400001,
          inherited_savings: -0.018091,
          unbilled_savings: 0,
          total_cud_savings: 0.133887,
          total_other_savings: 0,
        },
        {
          day: 3,
          period_title: 'Feb 03',
          base_savings: 0.110424,
          smart_savings: 0.04155,
          inherited_savings: -0.018085,
          unbilled_savings: 0,
          total_cud_savings: 0.133889,
          total_other_savings: 0,
        },
        {
          day: 4,
          period_title: 'Feb 04',
          base_savings: 0.110424000005,
          smart_savings: 0.041537999995,
          inherited_savings: -0.018087,
          unbilled_savings: 0,
          total_cud_savings: 0.133875,
          total_other_savings: 0,
        },
        {
          day: 5,
          period_title: 'Feb 05',
          base_savings: 0.110424000005,
          smart_savings: 0.041520999995,
          inherited_savings: -0.018088,
          unbilled_savings: 0,
          total_cud_savings: 0.133857,
          total_other_savings: 0,
        },
        {
          day: 6,
          period_title: 'Feb 06',
          base_savings: 0.110424000005,
          smart_savings: 0.041524999995,
          inherited_savings: -0.018091,
          unbilled_savings: 0,
          total_cud_savings: 0.133858,
          total_other_savings: 0,
        },
        {
          day: 7,
          period_title: 'Feb 07',
          base_savings: 0.110423999995,
          smart_savings: 0.041522000005,
          inherited_savings: -0.018088,
          unbilled_savings: 0,
          total_cud_savings: 0.133858,
          total_other_savings: 0,
        },
        {
          day: 8,
          period_title: 'Feb 08',
          base_savings: 0.110424000005,
          smart_savings: 0.041539999995,
          inherited_savings: -0.018086,
          unbilled_savings: 0,
          total_cud_savings: 0.133878,
          total_other_savings: 0.063254,
        },
        {
          day: 9,
          period_title: 'Feb 09',
          base_savings: 0.110424,
          smart_savings: 0.041522,
          inherited_savings: -0.018086,
          unbilled_savings: 0,
          total_cud_savings: 0.13386,
          total_other_savings: 0.063272,
        },
        {
          day: 10,
          period_title: 'Feb 10',
        },
        {
          day: 11,
          period_title: 'Feb 11',
        },
        {
          day: 12,
          period_title: 'Feb 12',
        },
        {
          day: 13,
          period_title: 'Feb 13',
        },
        {
          day: 14,
          period_title: 'Feb 14',
        },
        {
          day: 15,
          period_title: 'Feb 15',
        },
        {
          day: 16,
          period_title: 'Feb 16',
        },
        {
          day: 17,
          period_title: 'Feb 17',
        },
        {
          day: 18,
          period_title: 'Feb 18',
        },
        {
          day: 19,
          period_title: 'Feb 19',
        },
        {
          day: 20,
          period_title: 'Feb 20',
        },
        {
          day: 21,
          period_title: 'Feb 21',
        },
        {
          day: 22,
          period_title: 'Feb 22',
        },
        {
          day: 23,
          period_title: 'Feb 23',
        },
        {
          day: 24,
          period_title: 'Feb 24',
        },
        {
          day: 25,
          period_title: 'Feb 25',
        },
        {
          day: 26,
          period_title: 'Feb 26',
        },
        {
          day: 27,
          period_title: 'Feb 27',
        },
        {
          day: 28,
          period_title: 'Feb 28',
        },
      ],
      net_savings_trend_months: [
        {
          month_start: '2024-02-01T00:00:00Z',
          compute_engine_usage: 12.96,
          net_savings: -0.43,
        },
        {
          month_start: '2024-03-01T00:00:00Z',
          compute_engine_usage: 12.04,
          net_savings: -1.19,
          effective_savings_rate_percentage: -9.9,
          baseline_effective_savings_rate_percentage: -26.969033395479354,
        },
        {
          month_start: '2024-04-01T00:00:00Z',
          compute_engine_usage: 12.06,
          net_savings: -5.03,
          effective_savings_rate_percentage: -41.7,
          baseline_effective_savings_rate_percentage: -26.969033395479354,
        },
        {
          month_start: '2024-05-01T00:00:00Z',
          compute_engine_usage: 12.98,
          net_savings: -6.9,
          effective_savings_rate_percentage: -53.1,
          baseline_effective_savings_rate_percentage: -26.969033395479354,
        },
        {
          month_start: '2024-06-01T00:00:00Z',
          compute_engine_usage: 12.06,
          net_savings: -7.46,
          effective_savings_rate_percentage: -61.9,
          baseline_effective_savings_rate_percentage: -26.969033395479354,
        },
        {
          month_start: '2024-07-01T00:00:00Z',
          compute_engine_usage: 0.68,
          net_savings: -24.94,
          effective_savings_rate_percentage: -3684.2,
          baseline_effective_savings_rate_percentage: -26.969033395479354,
        },
        {
          month_start: '2024-08-01T00:00:00Z',
          compute_engine_usage: 0,
        },
        {
          month_start: '2024-09-01T00:00:00Z',
          compute_engine_usage: 0.18,
          net_savings: -25.79,
          effective_savings_rate_percentage: -14289.3,
          baseline_effective_savings_rate_percentage: -26.969033395479354,
        },
        {
          month_start: '2024-10-01T00:00:00Z',
          compute_engine_usage: 0,
        },
        {
          month_start: '2024-11-01T00:00:00Z',
          compute_engine_usage: 0,
        },
        {
          month_start: '2024-12-01T00:00:00Z',
          compute_engine_usage: 0,
        },
        {
          month_start: '2025-01-01T00:00:00Z',
          compute_engine_usage: 20.43,
          net_savings: -0.03,
          effective_savings_rate_percentage: -0.1,
          baseline_effective_savings_rate_percentage: -26.969033395479354,
        },
      ],
      spend_coverage_summary: {
        base_target: 0,
        smart_spend_coverage: 3.34,
        smart_spend_coverage_percentage: 38.35,
        base_spend_coverage: 2.31,
        base_spend_coverage_percentage: 26.52,
        inherited_spend_coverage: 0,
        inherited_spend_coverage_percentage: 0,
        unbilled_spend_coverage: 0,
        unbilled_spend_coverage_percentage: 0,
        overall_spend_coverage: 5.65,
        overall_spend_coverage_percentage: 64.87,
      },
      daily_spend_coverage: [
        {
          day: 1,
          compute_engine_usage: 0.9,
          inherited_spend_coverage: 0,
          smart_spend_coverage: 0.35,
          base_spend_coverage: 0.24,
          unbilled_spend_coverage: 0,
          overall_spend_coverage_percentage: 65.6,
        },
        {
          day: 2,
          compute_engine_usage: 0.9,
          inherited_spend_coverage: 0,
          smart_spend_coverage: 0.35,
          base_spend_coverage: 0.24,
          unbilled_spend_coverage: 0,
          overall_spend_coverage_percentage: 65.6,
        },
        {
          day: 3,
          compute_engine_usage: 0.9,
          inherited_spend_coverage: 0,
          smart_spend_coverage: 0.35,
          base_spend_coverage: 0.24,
          unbilled_spend_coverage: 0,
          overall_spend_coverage_percentage: 65.6,
        },
        {
          day: 4,
          compute_engine_usage: 0.9,
          inherited_spend_coverage: 0,
          smart_spend_coverage: 0.35,
          base_spend_coverage: 0.24,
          unbilled_spend_coverage: 0,
          overall_spend_coverage_percentage: 65.6,
        },
        {
          day: 5,
          compute_engine_usage: 0.9,
          inherited_spend_coverage: 0,
          smart_spend_coverage: 0.35,
          base_spend_coverage: 0.24,
          unbilled_spend_coverage: 0,
          overall_spend_coverage_percentage: 65.6,
        },
        {
          day: 6,
          compute_engine_usage: 0.9,
          inherited_spend_coverage: 0,
          smart_spend_coverage: 0.35,
          base_spend_coverage: 0.24,
          unbilled_spend_coverage: 0,
          overall_spend_coverage_percentage: 65.6,
        },
        {
          day: 7,
          compute_engine_usage: 0.9,
          inherited_spend_coverage: 0,
          smart_spend_coverage: 0.35,
          base_spend_coverage: 0.24,
          unbilled_spend_coverage: 0,
          overall_spend_coverage_percentage: 65.6,
        },
        {
          day: 8,
          compute_engine_usage: 0.9,
          inherited_spend_coverage: 0,
          smart_spend_coverage: 0.35,
          base_spend_coverage: 0.24,
          unbilled_spend_coverage: 0,
          overall_spend_coverage_percentage: 65.6,
        },
        {
          day: 9,
          compute_engine_usage: 0.9,
          inherited_spend_coverage: 0,
          smart_spend_coverage: 0.35,
          base_spend_coverage: 0.24,
          unbilled_spend_coverage: 0,
          overall_spend_coverage_percentage: 65.6,
        },
        {
          day: 10,
        },
        {
          day: 11,
        },
        {
          day: 12,
        },
        {
          day: 13,
        },
        {
          day: 14,
        },
        {
          day: 15,
        },
        {
          day: 16,
        },
        {
          day: 17,
        },
        {
          day: 18,
        },
        {
          day: 19,
        },
        {
          day: 20,
        },
        {
          day: 21,
        },
        {
          day: 22,
        },
        {
          day: 23,
        },
        {
          day: 24,
        },
        {
          day: 25,
        },
        {
          day: 26,
        },
        {
          day: 27,
        },
        {
          day: 28,
        },
      ],
      spend_coverage_trend: [
        {
          month_start: '2024-02-01T00:00:00Z',
          on_demand: 0.13,
          smart_resource_cud: 0,
          smart_spend_cud: 0,
          base_spend_cud: 0,
          inherited_resource_cud: 9.96,
          inherited_spend_cud: 2.87,
          unbilled_cud: 0,
        },
        {
          month_start: '2024-03-01T00:00:00Z',
          on_demand: -0.01,
          smart_resource_cud: 0,
          smart_spend_cud: 0,
          base_spend_cud: 0,
          inherited_resource_cud: 9.95,
          inherited_spend_cud: 2.1,
          unbilled_cud: 0,
          base_spend_coverage_target: 0,
        },
        {
          month_start: '2024-04-01T00:00:00Z',
          on_demand: 0,
          smart_resource_cud: 2.73,
          smart_spend_cud: 0,
          base_spend_cud: 0,
          inherited_resource_cud: 7.23,
          inherited_spend_cud: 2.1,
          unbilled_cud: 0,
          base_spend_coverage_target: 0,
        },
        {
          month_start: '2024-05-01T00:00:00Z',
          on_demand: 0.42,
          smart_resource_cud: 10.16,
          smart_spend_cud: 0.17,
          base_spend_cud: 0,
          inherited_resource_cud: 0,
          inherited_spend_cud: 2.23,
          unbilled_cud: 0,
          base_spend_coverage_target: 0,
        },
        {
          month_start: '2024-06-01T00:00:00Z',
          on_demand: 0,
          smart_resource_cud: 9.96,
          smart_spend_cud: 0,
          base_spend_cud: 0,
          inherited_resource_cud: 0,
          inherited_spend_cud: 2.1,
          unbilled_cud: 0,
          base_spend_coverage_target: 0,
        },
        {
          month_start: '2024-07-01T00:00:00Z',
          on_demand: 0.14,
          smart_resource_cud: 0.41,
          smart_spend_cud: 0.02,
          base_spend_cud: 0,
          inherited_resource_cud: 0.01,
          inherited_spend_cud: 0.1,
          unbilled_cud: 0,
          base_spend_coverage_target: 0,
        },
        {
          month_start: '2024-08-01T00:00:00Z',
        },
        {
          month_start: '2024-09-01T00:00:00Z',
          on_demand: 0,
          smart_resource_cud: 0.13,
          smart_spend_cud: 0,
          base_spend_cud: 0,
          inherited_resource_cud: 0,
          inherited_spend_cud: 0.05,
          unbilled_cud: 0,
          base_spend_coverage_target: 0,
        },
        {
          month_start: '2024-10-01T00:00:00Z',
        },
        {
          month_start: '2024-11-01T00:00:00Z',
        },
        {
          month_start: '2024-12-01T00:00:00Z',
        },
        {
          month_start: '2025-01-01T00:00:00Z',
          on_demand: 7.16,
          smart_resource_cud: 1.74,
          smart_spend_cud: 5.43,
          base_spend_cud: 5.43,
          inherited_resource_cud: 0.67,
          inherited_spend_cud: 0,
          unbilled_cud: 0,
          base_spend_coverage_target: 0,
        },
      ],
      portfolio_allocation: {
        on_demand_percentage: 15.45,
        other_percentage: 19.6,
        unbilled_one_year_percentage: 0,
        unbilled_three_year_percentage: 0,
        inherited_one_year_percentage: 0,
        inherited_three_year_percentage: 0,
        base_three_year_percentage: 26.57,
        smart_one_year_percentage: 36.02,
        smart_three_year_percentage: 2.36,
        sustained_use_discount_percentage: 19.6,
        on_demand_negotiated_percentage: 0,
        free_tier_percentage: 0,
        other_credits_percentage: 0,
        unbilled_one_year_discount_percentage: 0,
        unbilled_three_year_discount_percentage: 0,
        inherited_one_year_discount_percentage: 0,
        inherited_three_year_discount_percentage: 0,
        base_three_year_discount_percentage: 46.01,
        smart_one_year_discount_percentage: 12.76,
        smart_three_year_discount_percentage: 0.06,
        other_discount_percentage: 7.94,
        effective_discount_percentage: 19.37,
        sustained_use_discount_discount_percentage: 7.94,
        on_demand_negotiated_discount_percentage: 0,
        free_tier_discount_percentage: 0,
        other_credits_discount_percentage: 0,
      },
    },
  };
};

export const getCommitmentsDashboard = async billingAccountId => {
  try {
    const res = await api.get(`/v1/gcp_billing_accounts/${billingAccountId}/commitments_dashboard`);
    return res.data;
  } catch (e) {
    if (e.response?.status === 404) {
      return null;
    }
    throw e;
  }
};

export const requestCloudSQLSavingsAnalysis = billingAccountId => {
  const url = `/v1/gcp_billing_accounts/${billingAccountId}/cloud_sql_savings_analysis`;
  return api.post(url, {});
};

export const getServiceUsage = async (billingAccountId, timeframe) => {
  try {
    const res = await api.get(`/v1/gcp_billing_accounts/${billingAccountId}/service_usage`, {
      params: { timeframe },
    });
    return res.data;
  } catch (e) {
    if (e.response?.status === 404) {
      return null;
    }
    throw e;
  }
};
